<template>
  <div data-testid="store-select-button">
    <div
      class="w-full rounded-md py-2 store-select-shadow flex items-center font-semibold text-typography-secondary cursor-pointer"
      role="button"
      :class="{
        'justify-between px-4': !collapsed,
        'justify-center': collapsed,
      }"
      @click="handleStoreSelectClick"
    >
      <!-- company logo -->
      <div
        v-if="currentStore"
        class="flex gap-2 text-sm items-center w-full"
        :class="{
          'justify-center': collapsed,
        }"
      >
        <div
          v-if="!multipleStoresSelected"
          class="rounded h-6 w-6 border-neutral-300 border flex items-center justify-center flex-none object-cover bg-cover"
          :class="{
            'bg-neutral-300': !storeUrl,
          }"
        >
          <ClientOnly>
            <img v-if="storeUrl" :src="storeUrl" />
          </ClientOnly>
        </div>

        <span v-show="!collapsed" class="max-w-[125px] truncate text-xsm">
          {{ displayTitle }}
        </span>
      </div>
      <div v-else>
        <span class="text-xsm">Select a store</span>
      </div>
      <ChevronRightIcon v-show="!collapsed" v-if="allStores?.length > 1 || !currentStore" class="h-3 w-3" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { storeToRefs } from 'pinia';

import { useMerchantStore } from '~/store/merchant';

const store = useMerchantStore();
const allStores = store.allStores;

const { allSelectedCategory, multipleStoresSelected, currentStore } = storeToRefs(store);

const displayTitle = computed(() => {
  if (!currentStore.value) {
    return 'Select a store';
  }

  if (multipleStoresSelected.value) {
    if (allSelectedCategory.value) {
      return `All ${allSelectedCategory.value} stores`;
    }

    return 'Multiple selected';
  }

  return currentStore.value?.name;
});

const storeUrl = computed(() => {
  return currentStore.value.logo || currentStore.value?.storeSettings?.storeLogoUrl;
});

const emit = defineEmits(['store-select:click']);

const handleStoreSelectClick = () => {
  if (allStores?.value?.length <= 1) {
    return;
  }

  emit('store-select:click');
};

const collapsed = inject('collapsed', true);
</script>

<style>
.store-select-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
</style>
